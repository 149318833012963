import { useLocation } from "@remix-run/react";
import { createContext, useContext, useEffect, useState } from "react";
import { Linker } from "~/util/links";
import { useAccountIdx } from "./useAccountId";

export const LinkerContext = createContext<Linker | undefined>(undefined);

export const LinkerProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { accountIdx, domain } = useAccountIdx();

  const [linker, setLinker] = useState<Linker | undefined>(
    () => new Linker(accountIdx ?? 0, domain, location.pathname)
  );

  useEffect(() => {
    setLinker((linker) => linker?.with({ accountIdx, domain, pathName: location.pathname }));
  }, [accountIdx, domain, setLinker, location.pathname]);

  return <LinkerContext.Provider value={linker}>{children}</LinkerContext.Provider>;
};

export const useLinker = () => {
  const linker = useContext(LinkerContext);
  if (!linker) {
    throw new Error("useLinker must be used within a LinkerProvider");
  }
  return linker!;
};
