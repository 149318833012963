import { LoaderFunctionArgs, MetaFunction, json } from "@remix-run/node";
import { useLoaderData, useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import LoginPanel from "~/components/admin/authentication/LoginPanel";
import { useLinker } from "~/hooks/useLinker";
import { authenticator } from "~/services/auth.server";
import { getEnabledLoginMethods } from "~/services/auth.shared";
import { commitSession, getSession } from "~/services/session.server";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [{ title: `Subroutine - Login` }, { name: "description", content: "Subroutine Admin" }];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get("cookie") ?? "");
  const authError = session.get(authenticator.sessionErrorKey);
  const authUser = await authenticator.isAuthenticated(request);

  const url = new URL(request.url);
  let redirectTo = url.searchParams.get("redirectTo") ?? "";

  if (redirectTo && !authUser) {
    session.set("redirectTo", redirectTo);
  } else if (authUser && session.get("redirectTo")) {
    redirectTo = session.get("redirectTo");
    session.unset("redirectTo");

    // make sure redirectTo is a local url
    if (!redirectTo.startsWith("/")) {
      redirectTo = "";
    }
  }

  return json(
    {
      headerText: process.env.UI_LOGIN_HEADER_TEXT ?? "Welcome to Subroutine, login with:",
      redirectTo: redirectTo,
      enabledLoginMethods: Array.from(getEnabledLoginMethods()),
      authenticatedUser: authUser,
      authError: authError,
    },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    }
  );
}

// app/routes/login.tsx
export default function Login() {
  const { enabledLoginMethods, authenticatedUser, headerText, authError, redirectTo } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const linker = useLinker();

  useEffect(() => {
    if (!authenticatedUser) return;

    if ((redirectTo ?? "").length > 0) {
      navigate(redirectTo);
    } else {
      navigate(linker.home());
    }
  }, [authenticatedUser, redirectTo]);

  return (
    <LoginPanel
      headerText={headerText}
      loginMethods={enabledLoginMethods}
      isAuthenticated={!!authenticatedUser}
      authError={authError}
    />
  );
}
