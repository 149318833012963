import { Button, ButtonProps } from "@mantine/core";
import { IconBrandGoogle } from "@tabler/icons-react";

function GoogleIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return <IconBrandGoogle color="#4285F4" />;
}

export function GoogleButton(props: ButtonProps & React.ComponentPropsWithoutRef<"button">) {
  return <Button type="submit" leftSection={<GoogleIcon />} variant="default" {...props} />;
}
