import { Button, ButtonProps } from "@mantine/core";
import { IconBrandAzure } from "@tabler/icons-react";

export function EntraButton(props: ButtonProps & React.ComponentPropsWithoutRef<"button">) {
  return (
    <Button
      type="submit"
      leftSection={<IconBrandAzure style={{ width: "1rem", height: "1rem" }} color="#00ACEE" />}
      variant="default"
      {...props}
    />
  );
}
