import * as CookieConsent from "vanilla-cookieconsent";

export const commonSubroutineCookieConsentManagerConfig = (): CookieConsent.CookieConsentConfig => {
  return {
    categories: {
      necessary: {
        enabled: true,
        readOnly: true,
      },
      analytics: {},
    },
    language: {
      default: "en",
      translations: {
        en: {
          consentModal: {
            title: "We Value Your Privacy",
            description:
              "Subroutine uses cookies to improve your experience, analyze performance, and enhance our services. You can manage your preferences below.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Accept necessary",
            showPreferencesBtn: "Manage Preferences",
          },
          preferencesModal: {
            title: "Cookie Preferences",
            savePreferencesBtn: "Save Preferences",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Accept necessary",
            closeIconLabel: "Close",
            sections: [
              {
                title: "Necessary",
                description: "These cookies are necessary for the website to function properly.",
                linkedCategory: "necessary",
              },
              {
                title: "Performance and Analytics",
                description:
                  "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                linkedCategory: "analytics",
              },
            ],
          },
        },
      },
    },
    autoShow: true,
    hideFromBots: true,
    cookie: {
      name: "cookieconsent_status",
      domain: "subroutine.com",
      path: "/",
      expiresAfterDays: 182,
      sameSite: "Lax",
      useLocalStorage: false,
    },
  };
};
