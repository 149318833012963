const ROOT_PATH = "";
export const AUTO_BOT_ID = "auto";

type LinkerCloneProps = {
  accountIdx?: number;
  domain?: string;
  pathName?: string;
};

export class Linker {
  private _accountIdx: number = 0;
  private _domain: string | undefined = undefined;
  private _prevAccountInfo: string | undefined = undefined;

  public accountInfo: string = "";
  public path: string = "";

  constructor(accountIdx: number, domain?: string, path?: string) {
    this.setAccountInfo(accountIdx, domain);
    this.setCurrentPath(path ?? "");
  }

  with({ accountIdx, domain, pathName }: LinkerCloneProps = {}) {
    const linker = new Linker(
      typeof accountIdx !== "undefined" ? accountIdx : this._accountIdx,
      // if accountIdx was passed, don't use existing domain
      domain ?? (typeof accountIdx !== "undefined" ? undefined : this._domain),
      pathName ?? this.path
    );

    linker._prevAccountInfo = this.accountInfo;

    return linker;
  }

  setAccountInfo(accountIdx: number, domain?: string) {
    this.accountInfo = `${accountIdx}${typeof domain !== "undefined" && domain !== null ? "." + domain : ""}`;
  }

  setCurrentPath(path: string) {
    this.path = path;
  }

  public currentPath = () => {
    if (this._prevAccountInfo && this.path.startsWith("/" + this._prevAccountInfo)) {
      return "/" + this.accountInfo + this.path.slice(this._prevAccountInfo.length + 1);
    } else {
      return this.path;
    }
  };

  // public apps = () => `${ROOT_PATH}/${this.accountInfo}/apps`;
  // public appsBotDetails = (id: string, tab: string = "") => `${this.apps()}/bot/${id}/${tab}`;
  // public accountSettings = (tab: string = "") => `${ROOT_PATH}/${this.accountInfo}/account/${tab}`;
  // public accountSettingsRoleDetails = (roleId: string) => `${ROOT_PATH}/${this.accountInfo}/account/roles/${roleId}`;
  // public account = () => `${ROOT_PATH}/${this.accountInfo}/account/users`;
  // public analytics = (appId?: string) => `${ROOT_PATH}/${this.accountInfo}/data${appId ? `?appId=${appId}` : ""}`;
  // public userSettings = (tab: string = "security") => `${ROOT_PATH}/${this.accountInfo}/user/${tab}`;
  // public dataSources = () => `${ROOT_PATH}/${this.accountInfo}/dataSources`;
  // public dataSource = (id: string) => `${this.dataSources()}/${id}`;
  // public createAccount = () => `${ROOT_PATH}/createAccount`;
  // public accountSelect = () => `${ROOT_PATH}/accountSelect`;

  // CORE ROUTES
  public login = (redirectTo?: string | URL) => {
    if (!redirectTo) {
      return `${ROOT_PATH}/login`;
    } else if (typeof redirectTo !== "string") {
      return `${ROOT_PATH}/login?redirectTo=${encodeURIComponent(redirectTo.pathname + redirectTo.search)}`;
    } else {
      return `${ROOT_PATH}/login?redirectTo=${encodeURIComponent(redirectTo)}`;
    }
  };
  public logout = () => `${ROOT_PATH}/logout`;
  public unauthorizedPage = (message: string) => `${ROOT_PATH}/unauthorized?message=${encodeURIComponent(message)}`;
  public createAccount = () => `${ROOT_PATH}/createAccount`;
  public accountSelect = () => `${ROOT_PATH}/accountSelect`;

  // AUTH ROUTES
  public totpAuth = () => `${ROOT_PATH}/auth/totp`;
  // should this have _data?
  public totpVerify = (code?: string) => `${ROOT_PATH}/totp/verify${code ? `?code=${code}` : ""}`;
  public googleAuth = () => `${ROOT_PATH}/auth/google`;
  public googleAuthCallback = () => `${ROOT_PATH}/auth/google/callback`;
  public entraAuth = () => `${ROOT_PATH}/auth/entra`;
  public entraAuthCallback = () => `${ROOT_PATH}/auth/entra/callback`;
  public provideMfaCode = () => `${ROOT_PATH}/provideMfa`;

  // ADMIN PANEL ROUTES
  public dashboard = () => `${ROOT_PATH}/${this.accountInfo}/dashboard`;
  public agents = () => `${ROOT_PATH}/${this.accountInfo}/agents`;
  public home = () => this.agents();
  public agent = (id: string, tab?: string) => `${this.agents()}/${id}/${tab ? tab : ""}`;
  public agentDocumentContentReport = (id: string, documentId: string) =>
    `${this.agents()}/${id}/document/${documentId}/contentReport`;
  public websites = () => `${ROOT_PATH}/${this.accountInfo}/websites`;
  public website = (id: string) => `${this.websites()}/${id}`;
  public websiteUrlContentReport = (id: string, url: string) =>
    `${this.website(id)}/url/${btoa(url).replace(/\//g, "-")}/contentReport`;
  public websiteUrlLegacyReport = (id: string, url: string) =>
    `${this.website(id)}/url/${btoa(url).replace(/\//g, "-")}/legacyReport`;
  public reports = () => `${ROOT_PATH}/${this.accountInfo}/reports`;
  public data = () => `${ROOT_PATH}/${this.accountInfo}/data`;
  public settings = () => `${ROOT_PATH}/${this.accountInfo}/settings`;
  public settingsAccount = (tab?: string) => `${ROOT_PATH}/${this.accountInfo}/settings/account`;
  public roleDetails = (roleId: string) => `${this.settingsAccount()}/roles/${roleId}`;
  public selectOrganization = () => `${ROOT_PATH}/selectOrganization`;
  public mock = () => `${ROOT_PATH}/${this.accountInfo}/mock`;

  // DEPLOYMENT ADMIN ROUTES
  public deploymentAdmin = () => `${ROOT_PATH}/${this.accountInfo}/admin`;
  public internalTools = () => `${ROOT_PATH}/${this.accountInfo}/internalTools`;

  // DEMO ROUTES
  public demos = () => `${ROOT_PATH}/demo`;
  public demoInvestors = () => `${this.demos()}/csv-investors`;
  public demoCsv = () => `${this.demos()}/csv-contacts`;
  public demoEmailAssistant = () => `${this.demos()}/email-assistant`;
  public demoTranscription = () => `${this.demos()}/transcription`;

  // UPLOAD FILE ROUTES, THESE SHOULD BE DEPRECATED IN FAVOR OF PERSISTED URLS
  public docFilename = (id: string) => `/file/${id}-compressed.pdf`;
  public compressedDoc = (apiHost: string, id: string) => `${apiHost}${this.docFilename(id)}`;

  // SIMPLE APIS
  public apiUserAccounts = () => `${ROOT_PATH}/api/userAccounts`;
  public apiInstrumentAnalytics = () => `${ROOT_PATH}/api/instrumentAnalytics`;

  // CHAT ROUTES
  public agentChat = (id: string, name: string) =>
    id == AUTO_BOT_ID
      ? this.autoAgentChat()
      : `${ROOT_PATH}/agents/chat/${encodeURIComponent(id)}/${encodeURIComponent(name)}/view`;
  public agentEmbedTest = (id: string, name: string) =>
    id == AUTO_BOT_ID
      ? this.autoAgentChat()
      : `${ROOT_PATH}/agents/chat/${encodeURIComponent(id)}/${encodeURIComponent(name)}/embedTest`;
  public agentEmbed = (id: string, name: string) =>
    id == AUTO_BOT_ID
      ? this.autoAgentChat()
      : `${ROOT_PATH}/agents/chat/${encodeURIComponent(id)}/${encodeURIComponent(name)}/embed`;
  public agentEmbedScript = (id: string, name: string) =>
    id == AUTO_BOT_ID
      ? this.autoAgentChat()
      : `${ROOT_PATH}/dist/embed.js?id=${encodeURIComponent(id)}&title=${encodeURIComponent(name)}`;
  public autoAgentChat = () => `${ROOT_PATH}/agents/chat/auto/view`;
}
