import { modals } from "@mantine/modals";
import React, { useContext } from "react";

type PanelPortalData = typeof modals & {};

const PortalContext = React.createContext<PanelPortalData | null>(null);

export const usePanelPortal = () => {
  const context = useContext<PanelPortalData | null>(PortalContext);
  if (!context) {
    throw new Error("usePanelPortal must be used within a Panel");
  }
  return context;
};

export { PortalContext };
