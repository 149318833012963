import { useMatches } from "@remix-run/react";

export type AccountInfo = {
  accountIdx?: number;
  accountId?: string;
  domain?: string;
  accountName?: string;
};

export const ACCOUNT_REQUIRED_KEY = "accountRequired";

export const useAccountIdx = () => {
  const matches = useMatches();

  // filter to the match which matches the handle
  const match = matches.find((m) => (m.handle as Record<string, any>)?.[ACCOUNT_REQUIRED_KEY]);

  return (match?.data ?? {}) as AccountInfo;
};
