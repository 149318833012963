import { HeaderNames } from "@shared/config/headers";
import { GraphQLClient, GraphQLWebSocketClient } from "graphql-request";
import { Client, GRAPHQL_TRANSPORT_WS_PROTOCOL, createClient } from "graphql-ws";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

type SubroutineData = {
  accountId?: string;
  apiHost: string;
  apiUrl: string;
  transcriptionApi?: string;

  graphqlClient?: GraphQLClient;
  graphqlWSClient?: Client;
  setApiKey: (apiKey: string, secret: string) => void;
  setAccountId: (accountId: string) => void;
};

async function createSignature(clientSecret: any, payload: any) {
  // Encode the client secret and payload as Uint8Arrays
  const keyBytes = new TextEncoder().encode(clientSecret);
  const payloadBytes = new TextEncoder().encode(payload);

  // Import the client secret as a CryptoKey for HMAC using SHA-256
  const cryptoKey = await crypto.subtle.importKey("raw", keyBytes, { name: "HMAC", hash: { name: "SHA-256" } }, false, [
    "sign",
  ]);

  // Create the HMAC signature for the payload
  const signature = await crypto.subtle.sign("HMAC", cryptoKey, payloadBytes);

  // Convert the signature from ArrayBuffer to hex string
  const signatureArray = Array.from(new Uint8Array(signature));
  return signatureArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

export const getSigningFetch = () => {
  return async (uri: URL | RequestInfo, options: any = {}) => {
    const fetchOptions: Record<string, any> = { ...options };
    if (options.headers && options.headers[HeaderNames.UNSAFE_CLIENT_SECRET]) {
      // sign the payload
      const payload = options.body;
      fetchOptions.headers[HeaderNames.PAYLOAD_SIGNATURE] = await createSignature(
        options.headers[HeaderNames.UNSAFE_CLIENT_SECRET],
        payload
      );

      delete fetchOptions.headers[HeaderNames.UNSAFE_CLIENT_SECRET];
    }
    return fetch(uri, fetchOptions);
  };
};

export const SubroutineContext = createContext<SubroutineData | undefined>(undefined);

export const useSubroutine = () => {
  const context = useContext<SubroutineData | undefined>(SubroutineContext);
  if (!context) {
    throw new Error("useSubroutine must be used within a SubroutineProvider");
  }
  return context;
};

export const createWSClient = (url: string) => {
  let wsUrl = url.replace(/^http/, "ws");
  return new Promise<GraphQLWebSocketClient>((resolve) => {
    const socket = new WebSocket(wsUrl, GRAPHQL_TRANSPORT_WS_PROTOCOL) as unknown as WebSocket;
    const client: GraphQLWebSocketClient = new GraphQLWebSocketClient(socket, {
      onAcknowledged: (_p) => Promise.resolve(resolve(client)),
    });
  });
};

type ApiKeyConfig = {
  apiKey: string;
  secret: string;
};

export type SubroutineProviderProps = {
  api: string;
  jwt?: string;
  apiKey?: string;
  clientSecret?: string;
  accountId?: string;
  transcriptionApi?: string;
  children: React.ReactNode;
};

export const SubroutineProvider = ({ api, jwt, accountId, transcriptionApi, children }: SubroutineProviderProps) => {
  const [apiKeyData, setApiKeyData] = useState<ApiKeyConfig | undefined>(undefined);
  const subroutineData = useMemo(() => {
    return {
      apiHost: api,
      apiUrl: `${api}/api/graphql/main`,
      transcriptionApi: transcriptionApi ?? api,
    };
  }, [api]);

  const [graphqlClient, setGraphqlClient] = useState<GraphQLClient | undefined>(undefined);
  const [graphqlWSClient, setGraphqlWSClient] = useState<Client | undefined>(undefined);
  const [overrideAccountId, setOverrideAccountId] = useState<string | undefined>(() => accountId);

  const usedAccountId = useMemo(() => accountId ?? overrideAccountId, [accountId, overrideAccountId]);

  useEffect(() => {
    if (!subroutineData.apiUrl) {
      setGraphqlClient(undefined);
      setGraphqlWSClient(undefined);
      return;
    }

    const headers: Record<string, any> = {};
    if (usedAccountId) {
      headers[HeaderNames.ACCOUNT_ID] = usedAccountId;
    }
    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`;
    }
    if (apiKeyData) {
      headers[HeaderNames.API_KEY] = apiKeyData.apiKey;
      headers[HeaderNames.UNSAFE_CLIENT_SECRET] = apiKeyData.secret;
    }

    console.log("Setting", subroutineData.apiUrl, jwt, apiKeyData, usedAccountId);
    setGraphqlClient(
      new GraphQLClient(subroutineData.apiUrl, {
        headers,
        fetch: getSigningFetch(),
      })
    );

    let wsClient = createClient({
      url: subroutineData.apiUrl.replace(/^http/, "ws"),
      connectionParams: {
        headers,
      },
    });
    setGraphqlWSClient(wsClient);

    return () => {
      if (wsClient) {
        wsClient.dispose();
      }
    };
  }, [subroutineData.apiUrl, jwt, apiKeyData, usedAccountId]);

  return (
    <SubroutineContext.Provider
      value={{
        ...subroutineData,
        accountId: usedAccountId,
        graphqlClient,
        graphqlWSClient,
        setApiKey: (apiKey: string, secret: string) => {
          setApiKeyData({ apiKey, secret });
        },
        setAccountId: (accountId: string) => {
          setOverrideAccountId(accountId);
        },
      }}
    >
      {children}
    </SubroutineContext.Provider>
  );
};
