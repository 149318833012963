export type JWTUser = {
  jwt: string;
};

export enum LoginMethods {
  GOOGLE = "google",
  MICROSOFT_ENTRA_ID = "microsoft",
  GITHUB = "github",
  TOTP = "totp",
}

export type User = JWTUser;

export const getEnabledLoginMethods = () => {
  let enabledLoginMethods: Set<LoginMethods> = new Set();

  if ((process.env.GOOGLE_SECRET ?? "").length > 0) {
    enabledLoginMethods.add(LoginMethods.GOOGLE);
  }

  if ((process.env.MICROSOFT_ENTRA_CLIENT_SECRET ?? "").length > 0) {
    enabledLoginMethods.add(LoginMethods.MICROSOFT_ENTRA_ID);
  }

  if ((process.env.TOTP_ENABLED ?? "false") === "true") {
    enabledLoginMethods.add(LoginMethods.TOTP);
  }

  return enabledLoginMethods;
};
