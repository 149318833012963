export enum HeaderNames {
  ACCOUNT_ID = "x-account-id",
  CACHEFILE_PATH = "x-cachefile-path",
  VALIDATION_URL = "x-validation-url",
  GRAPHQL_INTERNAL_KEY = "x-subroutine-graphql-internal-key",
  INTERNAL_SERVICE_NAME = "x-subroutine-internal-service",
  SLACK_TEAM_ID = "x-team-id",
  SLACK_USER_ID = "x-user-id",
  API_KEY = "x-api-key",
  PAYLOAD_SIGNATURE = "x-signature",

  USER_TOKEN = "x-user-token",
  USER_TOKEN_PROVIDER = "x-user-token-provider",

  SUPPRESS_SENDING_EMAIL = "x-suppress-sending-email",
  OVERRIDE_AUTH_PROVIDER = "x-subroutine-override-auth-provider",

  // This is a special header that is used to pass the client secret to fetch and is
  // used to sign the payload before sending it to the server
  UNSAFE_CLIENT_SECRET = "x-unsafe-client-secret",
}
