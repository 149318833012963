import "@blocknote/mantine/style.css";
import "@mantine/carousel/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/code-highlight/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "./globals.css";

import { ColorSchemeScript, Container, MantineProvider, Paper, Text } from "@mantine/core";
import { ModalsProvider, modals } from "@mantine/modals";
import { LoaderFunctionArgs } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useNavigate,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { SubroutineProvider } from "./client/SubroutineProvider";
import { authenticator } from "./services/auth.server";
import { commonSubroutineCookieConsentManagerConfig } from "./util/cookieConsent";

import { useEffect, useMemo } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import { PortalContext } from "./components/adminNew/ui/PanelPortal";
import { useAccountIdx } from "./hooks/useAccountId";
import { EnvFlags } from "./hooks/useEnvFlags";
import { LinkerProvider } from "./hooks/useLinker";
import redirectStyles from "./redirect.module.css";
import { uiPrefs } from "./services/cookies.server";
import { theme } from "./theme";

export async function loader({ params, request }: LoaderFunctionArgs) {
  console.log("Calling root loader");
  const user = await authenticator.isAuthenticated(request);

  let externalHost = process.env.ADMIN_WEBAPP_EXTERNAL_HOST;
  if (!externalHost) {
    throw new Error("ADMIN_WEBAPP_EXTERNAL_HOST is not set");
  }

  const currentUrl = new URL(request.url);
  const targetUrl = new URL(externalHost);
  let shouldRedirectTo = null;

  if (currentUrl.hostname != targetUrl.hostname) {
    targetUrl.pathname = currentUrl.pathname;
    targetUrl.search = currentUrl.search;
    shouldRedirectTo = targetUrl.toString();
  }

  // get the preferences cookie
  const cookieHeader = request.headers.get("Cookie");
  const uiPreferences = (await uiPrefs.parse(cookieHeader)) || {};

  return json({
    [EnvFlags.IS_DEV]: process.env.NODE_ENV === "development",
    apiHost: process.env.CLIENT_API_HOST ?? "",
    jwt: user?.jwt,
    sentryDsn: process.env.SENTRY_DSN,
    colorScheme: uiPreferences.colorScheme ?? "auto",
    shouldRedirectTo,
  });
}

const initializeSentry = (dsn: string | undefined) => {
  Sentry.init({
    dsn,
  });
};

const IMPORTED_FONTS: string[] = [
  "family=Cabin",
  "family=Merriweather:ital,wght@0,300;1,300",
  "family=Quicksand:wght@300",
  "family=Raleway:wght@200;500;700;800",
  "family=Space+Mono",
  "family=Zilla+Slab:wght@300;400",
  "family=Nunito:ital,wght@0,200..1000;1,200..1000",
  "family=Work+Sans:ital,wght@0,100..900;1,100..900",
  "family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
];

export function Layout({ children }: { children: React.ReactNode }) {
  const joinedFonts = useMemo(() => IMPORTED_FONTS.join("&"), []);
  const { colorScheme } = useLoaderData<typeof loader>() ?? { colorScheme: "auto" };

  return (
    <html lang="en">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href={`https://fonts.googleapis.com/css2?&${joinedFonts}&display=swap`} rel="stylesheet"></link>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript defaultColorScheme={colorScheme} />
      </head>
      <body>
        <LinkerProvider>
          <MantineProvider theme={theme} defaultColorScheme={colorScheme} withGlobalClasses>
            {children}
          </MantineProvider>
        </LinkerProvider>

        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const { apiHost, jwt, sentryDsn, colorScheme, shouldRedirectTo } = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  useMemo(() => {
    initializeSentry(sentryDsn);
  }, [sentryDsn]);

  const { accountId } = useAccountIdx();

  useEffect(() => {
    CookieConsent.run(commonSubroutineCookieConsentManagerConfig());
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (shouldRedirectTo) {
        window.location.href = shouldRedirectTo;
      }
    }, 10000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [shouldRedirectTo]);

  if (shouldRedirectTo) {
    return (
      <Container size="xl" className={redirectStyles.redirectContainer}>
        <Paper radius="md" p="xl" withBorder>
          <Text ta="center" size="lg" fw={500}>
            Subroutine Dashboard has moved. You'll be <Link to={shouldRedirectTo}>redirected shortly</Link>.
          </Text>
        </Paper>
      </Container>
    );
  } else {
    return (
      <SubroutineProvider api={apiHost} jwt={jwt} accountId={accountId}>
        <PortalContext.Provider value={modals}>
          <ModalsProvider>
            <Outlet />
          </ModalsProvider>
        </PortalContext.Provider>
      </SubroutineProvider>
    );
  }
}

export const handle = {
  isRoot: true,
};
