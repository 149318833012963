import { Button, Collapse, Container, Divider, Group, Loader, Paper, Text, TextInput } from "@mantine/core";
import { FormErrors, useForm } from "@mantine/form";
import { Form, useNavigate } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useLinker } from "~/hooks/useLinker";
import { LoginMethods } from "~/services/auth.shared";
import { EntraButton } from "./EntraButton";
import { GoogleButton } from "./GoogleButton";
import styles from "./LoginPanel.module.css";

type LoginPanelProps = {
  headerText: string;
  loginMethods: LoginMethods[];
  isAuthenticated: boolean;
  authError: any;
};

export default function LoginPanel({ loginMethods, isAuthenticated, headerText, authError }: LoginPanelProps) {
  const linker = useLinker();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showInviteCode, setShowInviteCode] = useState<boolean>(false);
  const navigator = useNavigate();
  const form = useForm({
    initialValues: {
      email: "",
      inviteCode: "",
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      inviteCode: (val) => (showInviteCode && val.trim().length === 0 ? "Invite code is required" : null),
    },
  });

  useEffect(() => {
    if (authError?.message) {
      console.log("Set some errors", authError?.message);
      form.setErrors({ email: authError?.message });
    }
  }, [authError]);

  if (isAuthenticated) {
    return (
      <>
        <p>You are already logged in.</p>
        <Form method="post" action={linker.logout()}>
          <button>Logout</button>
        </Form>
      </>
    );
  }

  const totpPanel =
    loginMethods.indexOf(LoginMethods.TOTP) !== -1 ? (
      <>
        <Divider label="Or continue with email" labelPosition="center" my="lg" />

        <form
          onSubmit={form.onSubmit(async (vals, e) => {
            try {
              setDisabled(true);

              const formData = new FormData();
              formData.set("email", vals.email);
              if (vals.inviteCode.trim().length > 0) {
                formData.set("inviteCode", vals.inviteCode);
              }

              const response = await fetch(linker.totpAuth(), {
                method: "POST",
                body: formData,
              });
              if (response.ok) {
                navigator(linker.totpVerify());
              } else {
                const data = await response.json();
                const fieldName = data?.field ?? "email";
                let errorState: FormErrors = {
                  email: null,
                  inviteCode: null,
                };
                errorState[fieldName] = data?.error ?? "Invalid email";
                form.setErrors(errorState);
              }
            } catch (e) {
              console.error(e);
            } finally {
              setDisabled(false);
            }
          })}
        >
          <TextInput
            required
            label="Email"
            name="email"
            placeholder="hello@subroutine.com"
            {...form.getInputProps("email")}
            radius="md"
          />

          <Collapse in={showInviteCode}>
            <Group className={styles.inviteCodeBox}>
              <div className={styles.grow}>
                <TextInput label="Invite Code" placeholder="XXXXXX" {...form.getInputProps("inviteCode")} radius="md" />
              </div>
            </Group>
          </Collapse>

          <Button disabled={disabled} name="submit" type="submit" radius="xl" fullWidth className={styles.loginButton}>
            {disabled ? <Loader type="dots" /> : <>Login</>}
          </Button>

          <Button
            variant="subtle"
            fullWidth
            onClick={() => {
              setShowInviteCode(!showInviteCode);
            }}
          >
            {!showInviteCode ? "I have an invite code" : "I do not have an invite code"}
          </Button>
        </form>
      </>
    ) : null;

  return (
    <Container className={styles.panelContainer}>
      <Paper radius="md" p="xl" withBorder>
        <Text size="lg" fw={500}>
          {headerText}
        </Text>

        {loginMethods.indexOf(LoginMethods.GOOGLE) !== -1 && (
          <Form action={linker.googleAuth()} method="post">
            <Group grow mb="md" mt="md">
              <GoogleButton radius="xl">Google</GoogleButton>
            </Group>
          </Form>
        )}
        {loginMethods.indexOf(LoginMethods.MICROSOFT_ENTRA_ID) !== -1 && (
          <Form action={linker.entraAuth()} method="post">
            <Group grow mb="md" mt="md">
              <EntraButton radius="xl">Microsoft Entra</EntraButton>
            </Group>
          </Form>
        )}

        {totpPanel}
      </Paper>
    </Container>
  );
}
