import { createTheme } from "@mantine/core";

export const UI_PREFERENCES_COOKIE_NAME = "ui_preferences";

const CSSProperties = {
  fontFamily: `'Cabin', sans-serif`,
  headerFontFamily: `"Raleway", sans-serif`,
};

export const theme = createTheme({
  colors: {},
});
